import { CompanyHandshakeSubStatus } from 'models/CompanyHandshake';
import { HandshakeFilterEnum } from 'modules/Cabinet/HandshakedCompanies/HandshakeFilter';

export const SCOPE_COMPANIES = 'Companies';
export const SCOPE_COMPANY_HANDSHAKES = 'CompanyHandshake';

export const uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Можливість надіслати запит з\'явиться через {{nextAction}}',
      [CompanyHandshakeSubStatus.my]: 'Час очікування підтвердження другої сторони спливає {{nextAction}}',
      [CompanyHandshakeSubStatus.full]: 'Відкликати запит можна {{nextAction}}',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Запит буде дійсним протягом {{period}} годин для підтвердження другою стороною.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Підтвердити запит другої сторони. Скасувати можна буде тільки через {{period}} годин',
      [CompanyHandshakeSubStatus.full]: 'Розірвати зв\'язок. Після цього можливість надіслати запит з\'явиться через {{period}} годин',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Надіслати запит {{nextAction}}',
        [CompanyHandshakeSubStatus.my]: 'Чекання підтвердження другої сторони буде відкликано {{nextAction}}',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Розірвати зв\'язок {{nextAction}}',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Надіслати запит',
        // [CompanyHandshakeSubStatus.my]: 'Waiting apporve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Підтвердити запит',
        [CompanyHandshakeSubStatus.full]: 'Розірвати зв\'язок',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Всі',
        [HandshakeFilterEnum.full]: 'Прийняті',
        [HandshakeFilterEnum.my]: 'Відправлені',
        [HandshakeFilterEnum.target]: 'Отримані',
      },
    },
    messages: {
      error: 'Рукостискання відкликане',
      success: 'Рукостискання встановлено',
      info: 'Рукостискання надіслано',
    },
  },
  CompanyReviewsCreate: {
    title: 'Відгук о {{name}}',
  },
  fields: {
    name: {
      title: 'Компанія',
    },
    description: {
      title: 'Опис',
    },
    country: {
      title: 'Країна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Зв\'язок',
    },
  },
  show: {
    info: {
      created_date: 'на сервісі {{date}}',
      amount_of_workers: '{{amount}} осіб',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органіка',
    },
    about_us: 'Про нас',
    specialization: 'Спеціалізація',
    images: 'Фото',
    files: 'Документи',
    tabs: {
      goods: 'Товари',
      contacts: 'Контакти',
      reviews: 'Відгуки',
      history: 'Історія',
    },
  },
};

export const en: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'You will be able to send the request in {{nextAction}}',
      [CompanyHandshakeSubStatus.my]: 'Waiting for confirmation from the other party will expire in {{nextAction}}',
      [CompanyHandshakeSubStatus.full]: 'You can revoke the request in {{nextAction}}',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'The request will be valid for {{period}} hours for confirmation by the other party.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Confirm the request from the other party. You will be able to cancel it only in {{period}} hours',
      [CompanyHandshakeSubStatus.full]: 'Break the connection. After that, the ability to send a request will appear in {{period}} hours',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Send request {{nextAction}}',
        [CompanyHandshakeSubStatus.my]: 'Waiting for confirmation from the other party, {{nextAction}} will be revoked',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Break connection {{nextAction}}',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Send request',
        // [CompanyHandshakeSubStatus.my]: 'Waiting approve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Confirm request',
        [CompanyHandshakeSubStatus.full]: 'Break connection',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'All',
        [HandshakeFilterEnum.full]: 'Accepted',
        [HandshakeFilterEnum.my]: 'Sent',
        [HandshakeFilterEnum.target]: 'Received',
      },
    },
    messages: {
      error: 'Handshake revoked',
      success: 'Handshake established',
      info: 'Handshake sent',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review of {{name}}',
  },
  fields: {
    name: {
      title: 'Company',
    },
    description: {
      title: 'Description',
    },
    country: {
      title: 'Country',
    },
    rating: {
      title: 'Rating',
    },
    handshake: {
      title: 'Contact',
    },
  },
  show: {
    info: {
      created_date: 'on service {{date}}',
      amount_of_workers: '{{amount}} people',
      annual_turnover: '{{turnover}} mln/usd',
      is_organic: 'organic',
    },
    about_us: 'About us',
    specialization: 'Specialization',
    images: 'Photos',
    files: 'Documents',
    tabs: {
      goods: 'Goods',
      contacts: 'Contacts',
      reviews: 'Reviews',
      history: 'History',
    },
  },
};

export const ru: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Возможность отослать запрос появится через {{nextAction}}',
      [CompanyHandshakeSubStatus.my]: 'Время ожидания подтверждения второй стороны истекает {{nextAction}}',
      [CompanyHandshakeSubStatus.full]: 'Отозвать запрос можно {{nextAction}}',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Запрос будет действителен в течении {{period}} часов для подтверждения второй стороной.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Подтвердить запрос второй стороны. Отменить можно будет только через {{period}} часов',
      [CompanyHandshakeSubStatus.full]: 'Разорвать связь. После этого возможность выслать запрос появится через {{period}} часов',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Отослать запрос {{nextAction}}',
        [CompanyHandshakeSubStatus.my]: 'Ожидание подтверждения второй стороны, будет отозвано {{nextAction}}',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Разорвать связь {{nextAction}}',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Отослать запрос',
        // [CompanyHandshakeSubStatus.my]: 'Waiting apporve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Подтвердить запрос',
        [CompanyHandshakeSubStatus.full]: 'Разорвать связь',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Все',
        [HandshakeFilterEnum.full]: 'Принятые',
        [HandshakeFilterEnum.my]: 'Отправленые',
        [HandshakeFilterEnum.target]: 'Полученые',
      },
    },
    messages: {
      error: 'Рукопожатие отозвано',
      success: 'Рукопожатие установлено',
      info: 'Рукопожатие отослано',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review {{name}}',
  },
  fields: {
    name: {
      title: 'Компания',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'Страна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Связь',
    },
  },
  show: {
    info: {
      created_date: 'на сервисе {{date}}',
      amount_of_workers: '{{amount}} человек',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'О нас',
    specialization: 'Специализация',
    images: 'Фото',
    files: 'Документы',
    tabs: {
      goods: 'Товары',
      contacts: 'Контакты',
      reviews: 'Отзывы',
      history: 'История',
    },
  },
};

export const bg: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Опцията за изпращане на заявка ще се появи чрез {{nextAction}}',
      [CompanyHandshakeSubStatus.my]: 'Таймаут за потвърждение от втора страна {{nextAction}}',
      [CompanyHandshakeSubStatus.full]: 'Можете да отмените заявката {{nextAction}}',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Заявката ще бъде валидна {{period}} часа за потвърждение от втората страна.',
      [CompanyHandshakeSubStatus.my]: 'моят',
      [CompanyHandshakeSubStatus.opposite]: 'Потвърдете искането на втората страна. Анулирането ще бъде възможно само след {{period}} часа',
      [CompanyHandshakeSubStatus.full]: 'Прекъсване на връзката. След това възможността за изпращане на заявка ще се появи след {{period}} часа',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Изпращане на заявка {{nextAction}}',
        [CompanyHandshakeSubStatus.my]: 'Изчакване на потвърждение от втора страна, {{nextAction}} ще бъде отменено',
        // противоположно: 'Може да одобри в {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Прекъсване на връзката {{nextAction}}',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Изпращане на заявка',
        // [CompanyHandshakeSubStatus.my]: 'Изчакване на одобрение, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Потвърдете заявката',
        [CompanyHandshakeSubStatus.full]: 'Прекъсване на връзката',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Всички',
        [HandshakeFilterEnum.full]: 'Прието',
        [HandshakeFilterEnum.my]: 'Изпратено',
        [HandshakeFilterEnum.target]: 'Получено',
      },
    },
    messages: {
      error: 'Ръкостискането е отменено',
      success: 'Ръкостискането е установено',
      info: 'Ръкостискането е изпратено',
    },
  },
  CompanyReviewsCreate: {
    title: 'Преглед на {{name}}',
  },
  fields: {
    name: {
      title: 'Компания',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'Държава',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Връзка',
    },
  },
  show: {
    info: {
      created_date: 'в услугата {{date}}',
      amount_of_workers: '{{amount}} души',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'За нас',
    specialization: 'Специализация',
    images: 'Снимка',
    files: 'Документи',
    tabs: {
      goods: 'Стоки',
      contacts: 'Контакти',
      reviews: 'Рецензии',
      history: 'История',
    },
  },
};
